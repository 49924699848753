import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/locations' },
    {
      path: '/',
      component: () => import('../layouts/AppLayout.vue'),
      children: [
        {
          path: 'locations',
          meta: {
            permission: 'kiosks.view'
          },
          component: () => import('../pages/LocationsPage.vue')
        },
        {
          path: 'kiosks',
          meta: {
            permission: 'kiosks.view'
          },
          component: () => import('../pages/KiosksPage.vue')
        },
        {
          path: 'event-log',
          component: () => import('../pages/AlertsPage.vue')
        },
        {
          path: 'users',
          meta: {
            permission: 'users.view'
          },
          component: () => import('../pages/UsersPage.vue')
        },
        {
          path: 'roles',
          meta: {
            permission: 'roles.view'
          },
          component: () => import('../pages/RolesPage.vue')
        },
        {
          path: 'scopes',
          meta: {
            permission: 'scopes.view'
          },
          component: () => import('../pages/ScopesPage.vue')
        },
        {
          path: 'servers',
          meta: {
            permission: 'servers.view'
          },
          component: () => import('../pages/ServersPage.vue')
        },
        {
          path: 'portals',
          meta: {
            permission: 'portals.view'
          },
          component: () => import('../pages/PortalsPage.vue')
        },
        {
          path: 'releases',
          meta: {
            permission: 'releases.view'
          },
          component: () => import('../pages/ReleasesPage.vue')
        },
        {
          path: 'integrations',
          meta: {
            permission: 'projects.view'
          },
          component: () => import('../pages/IntegrationsPage.vue')
        },
        {
          path: 'proton',
          component: () => import('../pages/ProtonPage.vue')
        },
        {
          path: 'forbidden',
          component: () => import('../pages/ForbiddenPage.vue')
        }
      ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (!store.getters['auth/isLoggedIn']) {
    try {
      await store.dispatch('auth/getActiveSession')

      const session = store.getters['auth/session'];
      if(to.meta.permission && !session.permissions.includes(to.meta.permission)) {
        router.push('/forbidden');
      }
      else {
        return next();
      }
    } catch(err) {
      window.location.assign('/api/auth/sso?RelayState=' + window.location.origin + to.path);
    }
  } else {
    return next();
  }
})

axios.interceptors.response.use(response => {
  return response
}, error => {
 if (error.response.status === 401 && window.location.pathname != '/api/auth/sso') {
  window.location.assign('/api/auth/sso?RelayState=' + window.location)
 }
 return error
})

export default router
