import axios from 'axios'

const API_URL = '/api/auth/';

class AuthService {
  login() {
    window.location.assign(API_URL + 'sso');
  }

  logout() {
    localStorage.removeItem('user');
  }

  getSession() {
    return axios.get(API_URL + 'session', { withCredentials: true })
  }
}

export default new AuthService();