import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import OpenLayersMap from "vue3-openlayers";
import './assets/main.css';
import './assets/print.css';
import "vue3-openlayers/styles.css";
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios'

axios.defaults.withCredentials = true

loadFonts()

createApp(App)
  .use(vuetify)
  .use(OpenLayersMap)
  .use(router)
  .mount('#app')
