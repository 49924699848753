import AuthService from '@/services/auth'
  
export default {
    namespaced: true,
    state: {
        session: {},
        isLoggedIn: false,
    },
    getters: {
        session: state => state.session,
        user: state => state.session.user,
        permissions: state => state.session.permissions,
        token: state => state.session.access_token,
        isLoggedIn: state => !!state.session.user,
    },
    mutations: {
        setSession (state, val) {
            state.session = val
        },
    },
    actions: {
        getActiveSession ({ commit }) {
            return new Promise((resolve, reject) => {
                AuthService.getSession()
                .then(res => {
                    if (!res?.data?.email) {
                        throw new Error({ message: 'Invalid session' })
                    }
                    commit('setSession', res.data)
                    return resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    return reject(err.response)
                })
            })
        }
    }
}